import * as React from "react"
import Seo from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import './policies.scss'
import AniLink from "gatsby-plugin-transition-link/AniLink"








function PoliciesPage() {
    const data = useStaticQuery(
        graphql`
            query Policies {
                policies: wordpressPage(title: {eq: "Privacy Policy"}) {
                    title
                    content
                }
            }
    `
    )


    return (
        <>
            <Seo title="Policies" />
            <AniLink
                paintDrip
                to="/"
                duration={1.5}
                hex="#000000"
                className='go-back'
            >Go Back</AniLink>
            <div className="policies-container">
                <div className='policies-content'
                    dangerouslySetInnerHTML={{ __html: data.policies.content }}
                ></div>
            </div>
        </>
    )
}



export default PoliciesPage
